import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/base.js";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PatientSupportAccordion from "@components/patient-support-accordion";
import PatientSupportImage from "@components/patient-support-image";
import { Accordion, AccordionItem, AccordionHeading, AccordionPanel, Button, IconArrowRight, Link } from "@reusable";
import "./styles.scss";
import coPayCardGraphic from "./co-pay-card.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <div className="container prose animate-on-enter-1">
      <h2 style={{
        marginBottom: 0,
        marginTop: "1rem"
      }}>
ACCESS4Me offers a comprehensive list of programs
      </h2>
      <p className="text-center">
  Programs are designed so you can utilize product on hand without waiting for
  patient-specific shipments. For approved patients, simply utilize ROLVEDON
  <sup style={{
          "fontSize": "16px"
        }}>&reg;</sup> from your existing supply, then submit
  the Product Replacement Form. Replacement product will be shipped directly to the
  facility where ROLVEDON was administered.
      </p>
      <PatientSupportAccordion mdxType="PatientSupportAccordion" />
      <p className="text-small">
  For questions about applying for coverage, program eligibility criteria, or
  denied claims, call ACCESS4Me at{" "}
  <a href="tel:1-866-582-2737">
    866&#8209;582&#8209;2737 (866&#8209;58&#8209;CARES)
  </a>{" "}
  8:00 <span className="small-caps">AM</span> to 8:00{" "}
  <span className="small-caps">PM</span> (ET), Monday - Friday. Enroll your patient
  via the ACCESS4Me Provider Portal at{" "}
  <Link href="https://www.ACCESS4MePortal.com" mdxType="Link">ACCESS4MePortal.com</Link>. To
  enroll via written form, select the appropriate support option on the
  Enrollment Form and fax to 833-281-7416. To enroll by phone, your office or
  patients may call ACCESS4Me at{" "}
  <a href="tel:1-866-582-2737">
    866&#8209;582&#8209;2737 (866&#8209;58&#8209;CARES)
  </a>{" "}
  8:00 <span className="small-caps">AM</span> to 8:00{" "}
  <span className="small-caps">PM</span> (ET), Monday - Friday. Spectrum
  Pharmaceuticals reserves the right, at its sole discretion, to discontinue the
  Bridge Program, Copay Assistance Program, the Patient Assistance Program, the
  First-Cycle Patient Support Program, and the Claims Denial Program or may
  revise, change, or terminate these programs at any time.
      </p>
      <div className="patient-support-image-layout">
  <div className="enroll-acc">
        </div>
        <div className="patient-support-image-center-wrapper">
          <div className="patient-support-image-container">
          </div>
        </div>
      </div>
      <div className="one-column" style={{
        margin: '0rem 0'
      }}>
  
  <div>
  </div>
      </div>
      <div className="enroll-acc">
      </div>
      <p className="center ctas">
  <Link href="https://www.Access4MePortal.com" style={{
          fontWeight: 600,
          color: "#87189d",
          textDecoration: "none"
        }} mdxType="Link">
    Visit ACCESS4MePortal.com for online enrollment and access to tools, forms and
    resources.
  </Link>
  <span style={{
          display: "inline-block",
          color: "white",
          background: "#87189d",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          marginLeft: "0.75rem"
        }}>
    <IconArrowRight mdxType="IconArrowRight" />
  </span>
      </p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      